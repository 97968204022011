<script setup lang="ts">
import { PageUrl } from '~/types'
import Trustpilot from '~/components/Trustpilot.vue'
import SafeHtml from '../UI/SafeHtml.vue'

const { businessEmail } = useEnvVariables()
const { footer } = useFooterStore()

const pageUrl = PageUrl
</script>

<template>
  <footer class="relative mx-auto w-full flex flex-col">
    <div class="mx-auto w-full bg-slate-950">
      <div class="mx-auto w-screen px-4 py-8 xl:container md:py-16 xl:px-0">
        <div class="flex flex-col gap-8 text-sm md:flex-row md:justify-between">
          <div class="flex flex-col justify-center sm:justify-start items-center gap-8">
            <NuxtLink to="/" title="homepage">
              <img class="flex" src="/images/logo.png" height="100%" width="200" alt="risparmia tu logo"
                title="risparmia tu logo" />
            </NuxtLink>
            <div class="flex justify-center gap-4 md:justify-start">
              <NuxtLink v-if="footer?.x_url" :to="footer?.x_url" target="_blank" title="x social account" external>
                <img class="hover:brightness-200" src="/images/social/x.png" alt="X social account"
                  title="X social account" height="32" width="32" />
                <span class="sr-only">X account</span>
              </NuxtLink>
              <NuxtLink v-if="footer?.facebook_url" :to="footer?.facebook_url" target="_blank"
                title="facebook social account" external>
                <img class="hover:brightness-200" src="/images/social/facebook.png" alt="Facebook social account"
                  title="Facebook account" height="32" width="32" />
                <span class="sr-only">Facebook account</span>
              </NuxtLink>
              <NuxtLink v-if="footer?.instagram_url" :to="footer?.instagram_url" target="_blank"
                title="instagram social account" external>
                <img class="hover:brightness-200" src="/images/social/instagram.png" alt="Instagram social account"
                  title="Instagram account" height="32" width="32" />
                <span class="sr-only">Instagram account</span>
              </NuxtLink>
              <NuxtLink v-if="footer?.whatsapp_url" :to="footer?.whatsapp_url" target="_blank"
                title="whatsapp social account" external>
                <img class="hover:brightness-200" src="/images/social/whatsapp.png" alt="Whatsapp social account"
                  title="Whatsapp account" height="32" width="32" />
                <span class="sr-only">Whatsapp account</span>
              </NuxtLink>
            </div>
            <div class="flex w-fit justify-center md:justify-start gap-4">
              <Trustpilot />
            </div>
          </div>
          <div class="flex w-full flex-col gap-8 md:max-w-xs">
            <div
              class="inline-flex flex-wrap gap-1 justify-center md:justify-start items-start text-center text-gray-500 md:text-start">

              <SafeHtml v-if="footer?.contacts" :raw-html="footer?.contacts" tag="span" />
              <SafeHtml v-if="footer?.working_hours" :raw-html="footer?.working_hours" tag="span" />
            </div>
            <div class="text-center text-gray-500 md:text-start">
              <SafeHtml v-if="footer?.content" :raw-html="footer?.content" tag="span" />
              <a class="font-bold text-gray-400 hover:text-gray-300 hover:underline" :href="`mailto:${businessEmail}`"
                target="_blank" alt="Contattaci" title="Contattaci">{{ businessEmail }}
              </a><br />
              Tutti i diritti sono riservati.
            </div>
          </div>
          <div class="flex flex-col gap-8 sm:flex-row sm:flex-wrap sm:justify-around md:justify-end">
            <div class="flex flex-col justify-center gap-4 md:justify-start">
              <h2 class="text-sm text-[#AC5FE5] uppercase flex justify-center font-semibold md:justify-start">
                Risparmia Tu
              </h2>
              <ul class="flex flex-col gap-1 font-medium text-gray-500">
                <li class="h-12 sm:h-6 flex justify-start items-center">
                  <NuxtLink :to="`/${pageUrl.ABOUT_US}`" title="Chi Siamo">
                    <div class="hover:text-gray-300 hover:underline flex justify-center md:justify-start">
                      Chi Siamo
                    </div>
                  </NuxtLink>
                </li>
                <li class="h-12 sm:h-6 flex justify-start items-center">
                  <NuxtLink :to="`/${pageUrl.HOW_IT_WORKS}`" title="Contatti">
                    <div class="hover:text-gray-300 hover:underline flex justify-center md:justify-start">
                      Come Funziona
                    </div>
                  </NuxtLink>
                </li>
                <li class="h-12 sm:h-6 flex justify-start items-center">
                  <NuxtLink :to="`/${pageUrl.CONTACTS}`" title="Contatti">
                    <div class="hover:text-gray-300 hover:underline flex justify-center md:justify-start">
                      Contatti
                    </div>
                  </NuxtLink>
                </li>
                <li class="h-12 sm:h-6 flex justify-start items-center">
                  <NuxtLink :to="`/${pageUrl.HELP}`" title="Assistenza">
                    <div class="hover:text-gray-300 hover:underline flex justify-center md:justify-start">
                      Assistenza
                    </div>
                  </NuxtLink>
                </li>
                <li class="h-12 sm:h-6 flex justify-start items-center">
                  <NuxtLink :to="`/${pageUrl.WORK_WITH_US}`" title="Lavora con noi">
                    <div class="hover:text-gray-300 hover:underline flex justify-center md:justify-start">
                      Lavora con noi
                    </div>
                  </NuxtLink>
                </li>
                <li class="h-12 sm:h-6 flex justify-start items-center">
                  <NuxtLink :to="`/${pageUrl.TERMS_AND_CONDITIONS}`" title="Termini e Condizioni">
                    <div class="hover:text-gray-300 hover:underline flex justify-center md:justify-start">
                      Termini e Condizioni
                    </div>
                  </NuxtLink>
                </li>
                <li class="h-12 sm:h-6 flex justify-start items-center">
                  <NuxtLink :to="`/${pageUrl.PRIVACY_AND_COOKIES}`" title="Privacy e Cookie Poliy">
                    <div class="hover:text-gray-300 hover:underline flex justify-center md:justify-start">
                      Privacy e Cookie Policy
                    </div>
                  </NuxtLink>
                </li>
              </ul>
            </div>
            <div v-if="false" class="flex flex-col gap-4">
              <h2 class="text-sm text-gray-200 uppercase flex justify-center font-semibold md:justify-start">
                <NuxtLink :to="`/${pageUrl.BLOG}`" title="blog">
                  <div class="text-[#F2B71F] hover:underline flex justify-center md:justify-start">
                    Blog
                  </div>
                </NuxtLink>
              </h2>
              <ul class="flex flex-col gap-1 font-medium text-gray-500">
                <li class="h-12 sm:h-6 flex justify-start items-center">
                  <NuxtLink to="/" title="Ambiente">
                    <div class="hover:text-gray-300 hover:underline flex justify-center md:justify-start">
                      Ambiente
                    </div>
                  </NuxtLink>
                </li>
                <li class="h-12 sm:h-6 flex justify-start items-center">
                  <NuxtLink to="/" title="Guida e Consigli">
                    <div class="hover:text-gray-300 hover:underline flex justify-center md:justify-start">
                      Guida e Consigli
                    </div>
                  </NuxtLink>
                </li>
                <li class="h-12 sm:h-6 flex justify-start items-center">
                  <NuxtLink to="/" title="Ultime Offerte">
                    <div class="hover:text-gray-300 hover:underline flex justify-center md:justify-start">
                      Ultime Offerte
                    </div>
                  </NuxtLink>
                </li>
                <li class="h-12 sm:h-6 flex justify-start items-center">
                  <NuxtLink to="/" title="Teconologia">
                    <div class="hover:text-gray-300 hover:underline flex justify-center md:justify-start">
                      Tecnologia
                    </div>
                  </NuxtLink>
                </li>
                <li class="h-12 sm:h-6 flex justify-start items-center">
                  <NuxtLink to="/" title="Business">
                    <div class="hover:text-gray-300 hover:underline flex justify-center md:justify-start">
                      Business
                    </div>
                  </NuxtLink>
                </li>
                <li class="h-12 sm:h-6 flex justify-start items-center">
                  <NuxtLink to="/" title="Leggi e Normative">
                    <div class="hover:text-gray-300 hover:underline flex justify-center md:justify-start">
                      Leggi e Normative
                    </div>
                  </NuxtLink>
                </li>
              </ul>
            </div>
            <div v-if="false" class="flex flex-col gap-4">
              <h2 class="text-sm text-[#E95097] uppercase flex justify-center font-semibold md:justify-start">
                Energia
              </h2>
              <ul class="flex flex-col gap-1 font-medium text-gray-500">
                <li class="h-12 sm:h-6 flex justify-start items-center">
                  <NuxtLink to="/" title="Luce privati">
                    <div class="hover:text-gray-300 hover:underline flex justify-center md:justify-start">
                      Luce privati
                    </div>
                  </NuxtLink>
                </li>
                <li class="h-12 sm:h-6 flex justify-start items-center">
                  <NuxtLink to="/" title="Gas privati">
                    <div class="hover:text-gray-300 hover:underline flex justify-center md:justify-start">
                      Gas privati
                    </div>
                  </NuxtLink>
                </li>
                <li class="h-12 sm:h-6 flex justify-start items-center">
                  <NuxtLink to="/" title="Luce aziende">
                    <div class="hover:text-gray-300 hover:underline flex justify-center md:justify-start">
                      Luce aziende
                    </div>
                  </NuxtLink>
                </li>
                <li class="h-12 sm:h-6 flex justify-start items-center">
                  <NuxtLink to="/" title="Gas aziende">
                    <div class="hover:text-gray-300 hover:underline flex justify-center md:justify-start">
                      Gas aziende
                    </div>
                  </NuxtLink>
                </li>
              </ul>
            </div>
            <div v-if="false" class="flex flex-col gap-4">
              <h2 class="text-sm text-[#1FE5F2] uppercase flex justify-center font-semibold md:justify-start">
                Internet
              </h2>
              <ul class="flex flex-col gap-1 font-medium text-gray-500">
                <li class="h-12 sm:h-6 flex justify-start items-center">
                  <NuxtLink to="/" title="Fibra e ADSL privati">
                    <div class="hover:text-gray-300 hover:underline flex justify-center md:justify-start">
                      Fibra e ADSL privati
                    </div>
                  </NuxtLink>
                </li>
                <li class="h-12 sm:h-6 flex justify-start items-center">
                  <NuxtLink to="/" title="Fibra e ADSL aziende">
                    <div class="hover:text-gray-300 hover:underline flex justify-center md:justify-start">
                      Fibra e ADSL aziende
                    </div>
                  </NuxtLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-auto w-full p-4 bg-slate-950">
      <span class="flex flex-1 text-slate-500 text-xs">
        <span class="text-xs align-top">*</span>
        <SafeHtml v-if="footer?.last" :raw-html="footer?.last" tag="span" />
      </span>
    </div>
  </footer>
</template>
