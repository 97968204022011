<script setup lang="ts">

import LazyFooter from '~/components/Layout/Footer.vue'
import { useBannerStore } from '~/stores/useBannerStore';

const { loadBanner } = useBannerStore()
const { loadFooter } = useFooterStore()
const { isHydrated } = useHydration()
const { isDev } = useEnvVariables()

useAsyncData('banner', async () => loadBanner(), { server: false })
useAsyncData('footer', async () => loadFooter(), { server: true })
</script>

<template>
  <main v-if="isHydrated" class="flex h-full w-full flex-col bg-white text-slate-600" :class="{
    ['debug-screens']: isDev,
  }">
    <slot />
    <LazyFooter />
  </main>
</template>
